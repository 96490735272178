
import { defineComponent, reactive, watchEffect, toRefs, watch, ref, nextTick,h } from 'vue';
import { useI18nLocale  } from '../../../../../TUIPlugin/TUIi18n';
import { onClickOutside } from '@vueuse/core';
import { ElMessageBox, ElMessage } from 'element-plus'
import { useStore } from '@/store' 
import { delQuick } from '@/api'
import AddWords from './addWords.vue'

const Words = defineComponent({
  type: 'custom',
  props: {
    show: {
      type: Boolean,
      default: () => false,
    },
    isMute: {
      type: Boolean,
      default: () => false,
    },
    isH5: {
      type: Boolean,
      default: () => false,
    },
  },
  components:{
    AddWords
  },
  setup(props:any, ctx:any) {
    const data = reactive({
      show: false,
      isMute: false,
      locale: useI18nLocale(),
    });

    const dialog:any = ref();

    const store = useStore();
    watchEffect(() => {
      data.show = props.show;
      data.isMute = props.isMute;
      store.getQuickList()
    });
    
    const toggleShow = async () => {
      data.show = !data.show;
    };
    const addWordDialog:any = ref(null)
    // 添加快速回复
    const onAdd = async ()=>{
      addWordDialog.value.visible = true
    }

    onClickOutside(dialog, () => {
      data.show = false;
    });

    const select =  (item:any, index?:number) => {
      const text = item.content;
      toggleShow();
      Words.TUIServer.sendTextMessage(text);
    };
    const onDel = async (item:any)=>{
      await ElMessageBox.confirm(
        `Delete ${item.content}?`,
        'Warning',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning',
        }
      )
      await delQuick({id:item.id})
      ElMessage.success('Delete Quick Reply Success')
      store.getQuickList()
    }
    return {
      ...toRefs(data),
      toggleShow,
      select,
      dialog,
      onAdd,
      onDel,
      store,
      addWordDialog
    };
  },
});
export default Words;
