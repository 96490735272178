import { TUIComponents, TUICore } from "../TUIKit";
import { getSearchParams } from "@/utils/rsa";

// import TUICallKit
import { TUICallKit } from "../TUIKit/TUIPlugin/TUICallKit";
import { getChatKeyId } from "@/utils/env";
import { useStore } from "@/store";

const SDKAppID = getChatKeyId();

const userID: string = (getSearchParams()?.userID as string) || ""; // User ID
const userSig: string = (getSearchParams()?.userSig as string) || ""; // User ID
const TUIKit = TUICore.init({
  SDKAppID
});

TUIKit.use(TUIComponents);
TUIKit.use(TUICallKit);

TUIKit.getInstance().tim.setLogLevel(1);
export const loginIM = async () => {
  const store = useStore();
  const res = await store.getUserInfo();
  if (!res) {
    return;
  }
  TUIKit.login({
    userID,
    userSig
  });
};

export const setupIm = (app: any) => {
  app.use(TUIKit);
};
