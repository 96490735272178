export const translateJSON = [
  {
    en: "Auto",
    zh: "自动识别",
    code: "auto"
  },
  {
    en: "Arabic",
    zh: "阿拉伯语",
    code: "ar"
  },
  {
    en: "German",
    zh: "德语",
    code: "de"
  },
  {
    en: "English",
    zh: "英语",
    code: "en"
  },
  {
    en: "Spanish",
    zh: "西班牙语",
    code: "es"
  },
  {
    en: "French",
    zh: "法语",
    code: "fr"
  },
  {
    en: "Hindi",
    zh: "印地语",
    code: "hi"
  },
  {
    en: "Indonesian",
    zh: "印度尼西亚语",
    code: "id"
  },
  {
    en: "Italian",
    zh: "意大利语",
    code: "it"
  },
  {
    en: "Japanese",
    zh: "日语",
    code: "ja"
  },
  {
    en: "Korean",
    zh: "韩语",
    code: "ko"
  },
  {
    en: "Dutch",
    zh: "荷兰语",
    code: "nl"
  },
  {
    en: "Portuguese",
    zh: "葡萄牙语",
    code: "pt"
  },
  {
    en: "Russian",
    zh: "俄语",
    code: "ru"
  },
  {
    en: "hai",
    zh: "泰语",
    code: "th"
  },
  {
    en: "Vietnamese",
    zh: "越南语",
    code: "vi"
  },
  {
    en: "Chinese",
    zh: "简体中文",
    code: "zh-CHS"
  },
  {
    en: "Chinese",
    zh: "繁体中文",
    code: "zh-CHT"
  },
  {
    en: "Afrikaans",
    zh: "南非荷兰语",
    code: "af"
  },
  {
    en: "Amharic",
    zh: "阿姆哈拉语",
    code: "am"
  },
  {
    en: "Azeerbaijani",
    zh: "阿塞拜疆语",
    code: "az"
  },
  {
    en: "Belarusian",
    zh: "白俄罗斯语",
    code: "be"
  },
  {
    en: "Bulgarian",
    zh: "保加利亚语",
    code: "bg"
  },
  {
    en: "Bangla",
    zh: "孟加拉语",
    code: "bn"
  },
  {
    en: "Bosnian (Latin)",
    zh: "波斯尼亚语",
    code: "bs"
  },
  {
    en: "Catalan",
    zh: "加泰隆语",
    code: "ca"
  },
  {
    en: "Cebuano",
    zh: "宿务语",
    code: "ceb"
  },
  {
    en: "Corsican",
    zh: "科西嘉语",
    code: "co"
  },
  {
    en: "Czech",
    zh: "捷克语",
    code: "cs"
  },
  {
    en: "Welsh",
    zh: "威尔士语",
    code: "cy"
  },
  {
    en: "Danish",
    zh: "丹麦语",
    code: "da"
  },
  {
    en: "Greek",
    zh: "希腊语",
    code: "el"
  },
  {
    en: "Esperanto",
    zh: "世界语",
    code: "eo"
  },
  {
    en: "Estonian",
    zh: "爱沙尼亚语",
    code: "et"
  },
  {
    en: "Basque",
    zh: "巴斯克语",
    code: "eu"
  },
  {
    en: "Persian",
    zh: "波斯语",
    code: "fa"
  },
  {
    en: "Finnish",
    zh: "芬兰语",
    code: "fi"
  },
  {
    en: "Fijian",
    zh: "斐济语",
    code: "fj"
  },
  {
    en: "Frisian",
    zh: "弗里西语",
    code: "fy"
  },
  {
    en: "Irish",
    zh: "爱尔兰语",
    code: "ga"
  },
  {
    en: "Scots",
    zh: "苏格兰盖尔语",
    code: "gd"
  },
  {
    en: "Galician",
    zh: "加利西亚语",
    code: "gl"
  },
  {
    en: "Gujarati",
    zh: "古吉拉特语",
    code: "gu"
  },
  {
    en: "Hausa",
    zh: "豪萨语",
    code: "ha"
  },
  {
    en: "Hawaiian",
    zh: "夏威夷语",
    code: "haw"
  },
  {
    en: "Hebrew",
    zh: "希伯来语",
    code: "he"
  },
  {
    en: "Hindi",
    zh: "印地语",
    code: "hi"
  },
  {
    en: "Croatian",
    zh: "克罗地亚语",
    code: "hr"
  },
  {
    en: "Haitian",
    zh: "海地克里奥尔语",
    code: "ht"
  },
  {
    en: "Hungarian",
    zh: "匈牙利语",
    code: "hu"
  },
  {
    en: "Armenian",
    zh: "亚美尼亚语",
    code: "hy"
  },
  {
    en: "Igbo",
    zh: "伊博语",
    code: "ig"
  },
  {
    en: "Icelandic",
    zh: "冰岛语",
    code: "is"
  },
  {
    en: "Javanese",
    zh: "爪哇语",
    code: "jw"
  },
  {
    en: "Georgian",
    zh: "格鲁吉亚语",
    code: "ka"
  },
  {
    en: "Kazakh",
    zh: "哈萨克语",
    code: "kk"
  },
  {
    en: "Khmer",
    zh: "高棉语",
    code: "km"
  },
  {
    en: "Kannada",
    zh: "卡纳达语",
    code: "kn"
  },
  {
    en: "Kurdish",
    zh: "库尔德语",
    code: "ku"
  },
  {
    en: "Kyrgyz",
    zh: "柯尔克孜语",
    code: "ky"
  },
  {
    en: "Latin",
    zh: "拉丁语",
    code: "la"
  },
  {
    en: "Luxembourgish",
    zh: "卢森堡语",
    code: "lb"
  },
  {
    en: "Lao",
    zh: "老挝语",
    code: "lo"
  },
  {
    en: "Lithuanian",
    zh: "立陶宛语",
    code: "lt"
  },
  {
    en: "Latvian",
    zh: "拉脱维亚语",
    code: "lv"
  },
  {
    en: "Malagasy",
    zh: "马尔加什语",
    code: "mg"
  },
  {
    en: "Maori",
    zh: "毛利语",
    code: "mi"
  },
  {
    en: "Macedonian",
    zh: "马其顿语",
    code: "mk"
  },
  {
    en: "Malayalam",
    zh: "马拉雅拉姆语",
    code: "ml"
  },
  {
    en: "Mongolian",
    zh: "蒙古语",
    code: "mn"
  },
  {
    en: "Marathi",
    zh: "马拉地语",
    code: "mr"
  },
  {
    en: "Malay",
    zh: "马来语",
    code: "ms"
  },
  {
    en: "Maltese",
    zh: "马耳他语",
    code: "mt"
  },
  {
    en: "Hmong",
    zh: "白苗语",
    code: "mww"
  },
  {
    en: "Myanmar (Burmese)",
    zh: "缅甸语",
    code: "my"
  },
  {
    en: "Nepali",
    zh: "尼泊尔语",
    code: "ne"
  },
  {
    en: "Dutch",
    zh: "荷兰语",
    code: "nl"
  },
  {
    en: "Norwegian",
    zh: "挪威语",
    code: "no"
  },
  {
    en: "Nyanja (Chichewa)",
    zh: "齐切瓦语",
    code: "ny"
  },
  {
    en: "Querétaro Otomi",
    zh: "克雷塔罗奥托米语",
    code: "otq"
  },
  {
    en: "Punjabi",
    zh: "旁遮普语",
    code: "pa"
  },
  {
    en: "Polish",
    zh: "波兰语",
    code: "pl"
  },
  {
    en: "Pashto",
    zh: "普什图语",
    code: "ps"
  },
  {
    en: "Romanian",
    zh: "罗马尼亚语",
    code: "ro"
  },
  {
    en: "Sindhi",
    zh: "信德语",
    code: "sd"
  },
  {
    en: "Sinhala (Sinhalese)",
    zh: "僧伽罗语",
    code: "si"
  },
  {
    en: "Slovak",
    zh: "斯洛伐克语",
    code: "sk"
  },
  {
    en: "Slovenian",
    zh: "斯洛文尼亚语",
    code: "sl"
  },
  {
    en: "Samoan",
    zh: "萨摩亚语",
    code: "sm"
  },
  {
    en: "Shona",
    zh: "修纳语",
    code: "sn"
  },
  {
    en: "Somali",
    zh: "索马里语",
    code: "so"
  },
  {
    en: "Albanian",
    zh: "阿尔巴尼亚语",
    code: "sq"
  },
  {
    en: "Serbian (Cyrillic)",
    zh: "塞尔维亚语(西里尔文)",
    code: "sr-Cyrl"
  },
  {
    en: "Serbian (Latin)",
    zh: "塞尔维亚语(拉丁文)",
    code: "sr-Latn"
  },
  {
    en: "Sesotho",
    zh: "塞索托语",
    code: "st"
  },
  {
    en: "Sundanese",
    zh: "巽他语",
    code: "su"
  },
  {
    en: "Swedish",
    zh: "瑞典语",
    code: "sv"
  },
  {
    en: "Kiswahili",
    zh: "斯瓦希里语",
    code: "sw"
  },
  {
    en: "Tamil",
    zh: "泰米尔语",
    code: "ta"
  },
  {
    en: "Telugu",
    zh: "泰卢固语",
    code: "te"
  },
  {
    en: "Tajik",
    zh: "塔吉克语",
    code: "tg"
  },
  {
    en: "Filipino",
    zh: "菲律宾语",
    code: "tl"
  },
  {
    en: "Klingon",
    zh: "克林贡语",
    code: "tlh"
  },
  {
    en: "Tongan",
    zh: "汤加语",
    code: "to"
  },
  {
    en: "Turkish",
    zh: "土耳其语",
    code: "tr"
  },
  {
    en: "Tahitian",
    zh: "塔希提语",
    code: "ty"
  },
  {
    en: "Ukrainian",
    zh: "乌克兰语",
    code: "uk"
  },
  {
    en: "Urdu",
    zh: "乌尔都语",
    code: "ur"
  },
  {
    en: "Uzbek",
    zh: "乌兹别克语",
    code: "uz"
  },
  {
    en: "Xhosa",
    zh: "南非科萨语",
    code: "xh"
  },
  {
    en: "Yiddish",
    zh: "意第绪语",
    code: "yi"
  },
  {
    en: "Yoruba",
    zh: "约鲁巴语",
    code: "yo"
  },
  {
    en: "Yucatec",
    zh: "尤卡坦玛雅语",
    code: "yua"
  },
  {
    en: "Cantonese (Traditional)",
    zh: "粤语",
    code: "yue"
  },
  {
    en: "Zulu",
    zh: "南非祖鲁语",
    code: "zu"
  }
];
