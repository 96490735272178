<!-- 问候时间 -->
<template>
  <div v-if="src" :key="src" class="copy-image" @click.stop.right>
    <div ref="copyImg" class="img-out" >
      <img :src="src" class="failed-img"/>
    </div>
    <span class="copy-btn" @click.stop="copyChart">Copy</span>
  </div>
</template>
<script setup>
  import { defineProps, ref ,defineExpose } from "vue";
  import { ElMessage } from "element-plus";
  import { copyImageToClipboard } from '@/utils/imageUtil'

  const props = defineProps({
    src: String
  });
  
  const copyImg = ref();
  defineExpose({
    copyImg
  })
  const copyChart = () => {
    try {
        copyImageToClipboard(props.src)
      } catch (error) {
        // 复制charts为图片
        const el = copyImg.value;
        const range = document.createRange();
        const selection = window.getSelection();
        range.selectNode(el);
        selection.removeAllRanges();
        selection.addRange(range);
        document.execCommand("copy");
        selection.removeAllRanges();
        ElMessage.success("Copied  to clipboard!!!");
        copy_jpg(props.src)
    }
  };

  // 复制图片，支持jpg，png，传入图片url即可
  const copy_jpg = (url) => {
      url = url.replace('http://im','https://im')
      var canvas = document.createElement('canvas') // 创建一个画板
      let image = new Image()
      image.setAttribute("crossOrigin", 'Anonymous') //可能会有跨越问题
      image.src = url
      image.onload = () => {  // img加载完成事件
          canvas.width = image.width  //设置画板宽度
          canvas.height = image.height //设置画板高度
          canvas.getContext('2d').drawImage(image, 0, 0); //加载img到画板
          let url = canvas.toDataURL("image/png") // 转换图片为dataURL，格式为png
          clipboardImg(url) // 调用复制方法
      }
  }

  // 从url下载并复制图片，如果是png格式的图片直接调用此方法就能复制成功
  const clipboardImg = async (url) => {
      try {
          const data = await fetch(url);
          const blob = await data.blob();
          await navigator.clipboard.write([
              new window.ClipboardItem({
                  [blob.type]: blob
              })
          ]);
          console.log('复制成功')
      } catch (err) {
          console.log('复制失败', err)
      }
  }
</script>
<style lang="scss" scoped>
  .copy-image {
    width: 100px;
    height: 100px;
    display: inline-block;
    margin: 20px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;

    .copy-btn {
      cursor: pointer;
      color: #1fc9c2;
    }
    .img-out,.failed-img{
      width: 100%;
      height: 100%;
      user-select: initial;
      pointer-events: unset;
    }
    .failed-img {
      object-fit: contain;
    }
  }
</style>
