
import { defineComponent, reactive, toRefs, computed, watch, onBeforeUnmount } from 'vue';
import TUIConversationList from './components/list';
import { caculateTimeago, isArrayEqual } from '../utils';
import { handleAvatar, handleName, handleShowLastMessage, handleAt } from '../TUIChat/utils/utils';
import { loginIM } from "@/plugins/im";

const TUIConversation = defineComponent({
  name: 'TUIConversation',

  components: {
    TUIConversationList,
  },
  props: {
    displayOnlineStatus: {
      type: Boolean,
      default: false,
    },
  },
  setup(props: any, ctx: any) {
    const TUIServer: any = TUIConversation?.TUIServer;

    const data = reactive({
      currentConversationID: '',
      conversationData: {
        list: [],
        handleItemAvator: (item: any) => handleAvatar(item),
        handleItemName: (item: any) => handleName(item),
        handleShowAt: (item: any) => handleAt(item),
        handleShowMessage: (item: any) => handleShowLastMessage(item),
        handleItemTime: (time: number) => {
          if (time > 0) {
            return caculateTimeago(time * 1000);
          }
          return '';
        },
      },
      userIDList: new Set(),
      netWork: '',
      env: TUIServer.TUICore.TUIEnv,
      displayOnlineStatus: false,
      userStatusList: TUIServer.TUICore.TUIServer.TUIContact?.currentStore?.userStatusList,
    });

    TUIServer.bind(data);

    TUIConversationList.TUIServer = TUIServer;

    watch(
      () => data.currentConversationID,
      (newVal: any) => {
        ctx.emit('current', newVal);
      },
      {
        deep: true,
      }
    );

    watch(
      () => props.displayOnlineStatus,
      async (newVal: any, oldVal: any) => {
        if (newVal === oldVal) return;
        data.displayOnlineStatus = newVal;
        TUIServer.TUICore.TUIServer.TUIContact.handleUserStatus(data.displayOnlineStatus, [...data.userIDList]);
        data.userStatusList = TUIServer.TUICore.TUIServer.TUIContact?.currentStore?.userStatusList;
      },
      { immediate: true }
    );

    watch(
      () => [...data.userIDList],
      async (newVal: any, oldVal: any) => {
        if (isArrayEqual(newVal, oldVal)) return;
        TUIServer.TUICore.TUIServer.TUIContact.handleUserStatus(data.displayOnlineStatus, [...data.userIDList]);
      },
      {
        deep: true,
      }
    );

    const isNetwork = computed(() => {
      const disconnected = data.netWork === TUIServer.TUICore.TIM.TYPES.NET_STATE_DISCONNECTED;
      const connecting = data.netWork === TUIServer.TUICore.TIM.TYPES.NET_STATE_CONNECTING;
      return disconnected || connecting;
    });

    // 重新登录，刷新当前会话消息
    const refreshCurrentConversation = () => {
      const current = data.conversationData.list.find((v:any) => v.conversationID === data.currentConversationID)
      handleCurrentConversation({currentConversationID:''});
      setTimeout(() => {
        handleCurrentConversation(current);
      }, 0);
      TUIServer.TUICore.tim.off(TUIServer.TUICore.TIM.EVENT.SDK_READY, refreshCurrentConversation);
    }
    const reLoginMessage = (res:any)=>{
      const { type,refresh } = res.data;
      if (type === "reLogin") {
        loginIM()
        if(refresh){
          TUIServer.TUICore.tim.on(TUIServer.TUICore.TIM.EVENT.SDK_READY, refreshCurrentConversation);
        }
      }
    }     

    window.addEventListener("message",reLoginMessage);
    onBeforeUnmount(()=>{
      window.removeEventListener("message",reLoginMessage)
    })


    const handleCurrentConversation = (value: any) => {
      TUIServer.handleCurrentConversation(value);
    };

    const handleDeleteConversation = (value: any) => {
      let index = data.conversationData.list.findIndex(item => {
        return item['conversationID'] == value['conversationID']
      })
      
      data.conversationData.list.splice(index, 1)
    }

    return {
      ...toRefs(data),
      handleCurrentConversation,
      isNetwork,
      handleDeleteConversation
    };
  },
});
export default TUIConversation;
