
import TUIAegis from '../../../../../utils/TUIAegis';
import { defineComponent, reactive, ref, toRefs, watch, watchEffect, computed } from 'vue';
import LiteItem from '../list-item';
import { getInstance } from "@/utils/indexdb";
import { ElMessage } from 'element-plus'
import { Search } from '@element-plus/icons-vue'
const TUIConversationList: any = defineComponent({
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    currentID: {
      type: String,
      default: () => '',
    },
    isH5: {
      type: Boolean,
      default: () => false,
    },
    displayOnlineStatus: {
      type: Boolean,
      default: () => false,
    },
    userStatusList: {
      type: Map,
      default: () => new Map(),
    },
  },
  emits: ['handleItem','handleDeleteConversation'],
  components: { LiteItem },
  setup(props: any, ctx: any) {
    const TUIServer: any = TUIConversationList?.TUIServer;
    const obj = reactive({
      data: {list:[]},
      currentID: '',
      toggleID: '',
      displayOnlineStatus: false,
      userStatusList: new Map(),
      types: TUIServer.TUICore.TIM.TYPES,
      tagList:[],
      search:'',
    });
    const list: any = ref();
    const addTagDialog = reactive({
      conversation:{
        tags: [],
        conversationID: '',
      },
      visible:false,
      tag:'',
      conversationID:''
    })

    const dataList = computed(()=>{
      return obj.data.list.filter((v:any)=>{
        if(v.type === 'C2C'){
          return v?.userProfile?.nick ? v.userProfile.nick.includes(obj.search) :  v.userProfile.userID.includes(obj.search)
        }else{
          return v?.groupProfile?.name?.includes(obj.search) 
        }
        
      })
    })

    watchEffect(async () => {
      obj.data = props.data;
      obj.currentID = props.currentID;
      obj.displayOnlineStatus = props.displayOnlineStatus;
      obj.userStatusList = props.userStatusList;
      obj.data?.list?.forEach(async (cv:any)=>{
        const item:any =  await getInstance().query_by_primaryKey({
          tableName: 'conversation_tags',
          value:cv.conversationID
        })
        cv.tags = item?.tags || []
      })
    });

    watch(
      () => props.userStatusList,
      () => {
        obj.userStatusList = props.userStatusList;
      },
      { deep: true }
    );

    const handleListItem = (item: any) => {
      ctx.emit('handleItem', item);
    };

    const handleItem = (params: any) => {
      const { name, conversation, other } = params;
      TUIAegis.getInstance().reportEvent({
        name: 'conversationOptions',
        ext1: 'conversationOptions',
      });
      switch (name) {
        case 'delete':
          handleDeleteConversation(conversation);
          break;
        case 'ispinned':
          handlePinConversation(name, conversation);
          break;
        case 'dispinned':
          handlePinConversation(name, conversation);
          break;
        case 'mute':
          handleMuteConversation(name, conversation);
          break;
        case 'notMute':
          handleMuteConversation(name, conversation);
          break;
        case 'addTag':
          handleAddTagConversation(conversation);
          break;
        case 'delTag':
          handleDelTagConversation(conversation,other);
          break;
      }
    };

    const handleDeleteConversation = (conversation: any) => {
      ctx.emit('handleDeleteConversation', conversation)
      TUIServer.handleCurrentConversation({});
      
      // TUIServer.deleteConversation(conversation.conversationID).then((imResponse: any) => {
      //   TUIAegis.getInstance().reportEvent({
      //     name: 'conversationOptions',
      //     ext1: 'conversationDelete',
      //   });
      //   const { conversationID } = imResponse.data;
      //   const { conversation } = TUIServer.TUICore.getStore().TUIChat;
      //   // 删除会话，判断当前删除的会话是否为打开的会话
      //   // 若为打开的会话，通知 TUIChat 关闭当前会话
      //   // Delete session: judge whether the currently deleted session is an open session
      //   // If it is an open session, notify tuichat to close the current session
      //   if (conversation.conversationID === conversationID) {
      //     TUIServer.TUICore.getStore().TUIChat.conversation = {
      //       conversationID: '',
      //     };
      //   }
      // });
    };

    const handlePinConversation = (type: string, conversation: any) => {
      const options: any = {
        conversationID: conversation.conversationID,
        isPinned: true,
      };

      if (type === 'dispinned') {
        options.isPinned = false;
      }
      TUIServer.pinConversation(options);
    };

    const handleMuteConversation = (type: string, conversation: any) => {
      const options: any = {
        messageRemindType: TUIServer.TUICore.TIM.TYPES.MSG_REMIND_ACPT_NOT_NOTE,
      };

      if (conversation.type === TUIServer.TUICore.TIM.TYPES.CONV_C2C) {
        options.userIDList = [conversation.userProfile.userID];
      } else if (conversation.type === TUIServer.TUICore.TIM.TYPES.CONV_GROUP) {
        options.groupID = conversation.groupProfile.groupID;
      }

      if (type === 'notMute') {
        options.messageRemindType = TUIServer.TUICore.TIM.TYPES.MSG_REMIND_ACPT_AND_NOTE;
      }

      TUIServer.muteConversation(options);
    };

    const handleToggleListItem = (conversationID: string) => {
      obj.toggleID = conversationID;
    };

    const scrollChange = () => {
      obj.toggleID && (obj.toggleID = '');
    };

    const handleAddTagConversation = (conversation: any) => {
      addTagDialog.conversation = conversation
      addTagDialog.visible = true
    }

    const addTagSure = async ()=>{
      await getInstance().insert({
        tableName: 'conversation_tags',
        data: {
          id: addTagDialog.conversation.conversationID || '',
          tags: [...addTagDialog.conversation.tags,addTagDialog.tag]
        }
      })
      const index = obj.data?.list?.findIndex((cv:any)=>cv.conversationID === addTagDialog.conversation.conversationID)
      Object.assign(obj.data.list[index],{tags:[...addTagDialog.conversation.tags,addTagDialog.tag]})
      addTagDialog.visible = false
      addTagDialog.tag = ''
      ElMessage.success('Add Tags Successed')
    }

    const handleDelTagConversation = async (conversation:any,index:any)=>{
      const tags = JSON.parse(JSON.stringify(conversation.tags))
            tags.splice(index,1)

      await getInstance().update_by_primaryKey({
        tableName: 'conversation_tags',
          value:conversation.conversationID,
          handle: (r:any) => {
            r.tags = tags;
            return r;
          }
      })
      conversation.tags = tags
      ElMessage.success('Delete Tags Successed')

    }

    return {
      ...toRefs(obj),
      addTagDialog,
      handleListItem,
      handleItem,
      handleToggleListItem,
      list,
      scrollChange,
      addTagSure,
      dataList,
      Search
    };
  },
});
export default TUIConversationList;
