
import { onClickOutside, useElementBounding } from '@vueuse/core';
import { defineComponent, nextTick, reactive, ref, toRefs, watch, watchEffect } from 'vue';
import { Conversation } from '../../interface';
const ListItem: any = defineComponent({
  props: {
    conversation: {
      type: Object,
      default: () => ({}),
    },
    handleConversation: {
      type: Object,
      default: () => ({}),
    },
    currentID: {
      type: String,
      default: () => '',
    },
    toggleID: {
      type: String,
      default: () => '',
    },
    isH5: {
      type: Boolean,
      default: () => false,
    },
    displayOnlineStatus: {
      type: Boolean,
      default: () => false,
    },
    userStatusList: {
      type: Map,
      default: () => new Map(),
    },
    types: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props: any, ctx: any) {
    const data = reactive({
      conversation: {} as Conversation,
      currentID: '',
      currentConversation: {},
      toggle: false,
      currentuserID: '',
      conversationType: '',
      loop: 0,
      displayOnlineStatus: false,
      userStatusList: new Map(),
    });

    const dialog: any = ref();
    const content: any = ref();

    onClickOutside(content, () => {
      if (data.toggle === true) {
        ctx.emit('toggle', '');
      }
    });

    watchEffect(() => {
      data.conversation = props.conversation;
      data.currentID = props.currentID;
      data.toggle = false;
      data.displayOnlineStatus = props.displayOnlineStatus;
      data.userStatusList = props.userStatusList;
      props.toggleID === props.conversation.conversationID && (data.toggle = true);
    });

    watch(
      () => data.toggle,
      (val: boolean) => {
        if (val) {
          nextTick(() => {
            const DialogBound = useElementBounding(dialog);
            const ParentEle = content?.value?.offsetParent;
            const ParentBound = useElementBounding(ParentEle);
            if (DialogBound.top.value - ParentBound.top.value - DialogBound.height.value - 30 > 0) {
              dialog.value.style.top = 'auto';
              dialog.value.style.bottom = '30px';
            }
          });
        }
      }
    );

    const handleListItem = (item: any) => {
      ctx.emit('open', item);
      ctx.emit('toggle', '');
    };

    const toggleDialog = (e?: any) => {
      if (e?.target?.oncontextmenu) {
        e.target.oncontextmenu = function () {
          return false;
        };
      }
      ctx.emit('toggle', (data.conversation as any).conversationID);
    };

    const handleItem = (name: string, other:any) => {
      ctx.emit('handle', {
        name,
        conversation: data.conversation,
        other
      });
      ctx.emit('toggle', '');
    };

    const showUserOnlineStatus = () => {
      if (data.displayOnlineStatus && data.conversation?.type === props.types.CONV_C2C) return true;
      return false;
    };

    return {
      ...toRefs(data),
      handleListItem,
      handleItem,
      dialog,
      content,
      toggleDialog,
      showUserOnlineStatus,
    };
  },
});
export default ListItem;
