import { getChatTopUrl } from "@/utils/env";
const topUrl = document.referrer;
const chatTopUrl = getChatTopUrl();
const isAllow =
  !chatTopUrl || topUrl.replace("https://", "").replace("www.", "").replace("rx.", "").startsWith(chatTopUrl);
if (window.parent === window.self || !isAllow) {
  location.href = "about:blank";
}

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import { setupIm } from "./plugins/im";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import copy from "@/utils/copy";
import highlight from "@/utils/highlight";
import { init } from "@/utils/indexdb";
import { createPinia } from "pinia";

let app = createApp(App);

setupIm(app);

init({
  dbName: "conversation", // 数据库名称
  version: 1, // 版本号
  tables: [
    {
      tableName: "conversation_tags", // 表名
      option: { keyPath: "id", autoIncrement: true }, // 指明主键为id
      indexs: [
        // 数据库索引
        {
          key: "id",
          option: {
            unique: true
          }
        },
        {
          key: "tags"
        }
      ]
    }
  ]
});

app.use(createPinia()).use(ElementPlus).use(router).use(copy).use(highlight).mount("#app");
