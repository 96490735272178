import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Error from "../views/Error.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: Home
  },
  {
    path: "/error",
    name: "error",
    component: Error
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
