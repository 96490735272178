import { JSEncrypt } from "encryptlong";
import qs from "qs";

const pk = `-----BEGIN PRIVATE KEY-----
MIICeAIBADANBgkqhkiG9w0BAQEFAASCAmIwggJeAgEAAoGBAPzwAyC5tnobJfRR
5EJAcua/QMWPmY4evJ/weYyBeDNFLW0RyQcE4lrVoaFiMwWSn6ekeZ3QTngzDslt
o+5SJ1e6sH/s1lHLPrklcvMnP236HGF+lyHKH0Tg2SZvK4Spz8fGwzuB3v0Yrrdi
hDouGEtm0UXX6ZKoD1YLwC/IDb0tAgMBAAECgYEA5KNsoGNfcKcjuuNfqwepFa4i
NbNlwdNE/Gqa5QiPiADrXCFsj1y55BQiY8hpiIsOqLKDgMUweXTl0+y494/poJbF
DJgde1gYZOBLaskTBEftRvTKDnn39bpoUr7gu42Td8v7sdtCdZ2oJV+0HmqdZGdS
gV6FlhhJa8QeP+0XAkECQQD+lBlcjhyUzSHa054pVp6/PMyAIp/S8bkjMmBq+1Kv
E++Q2fKmmolAxATB1K8k23YluIiYtkmwiRK08mjarzr9AkEA/lmRSJhcvMX/6qYP
WfjPUirRIABljGDun8/8QcCQknFSVFgi1m9NqfkyGVXNLxFAl9N+3TA7Q7YtgEVw
WQ6Z8QJBAMMXXYQnR9HkjbAzgb4uKkNlZVZAsngmU/mcDnWNmeSfg3RfAWwZe1tN
nFOqGHpHUHhBvTRCZaBKuzI9nosPxEUCQQCZ9q/aKNiiMJzMdn7+cH1W+LIkU/kk
c5yewrCpQJ6C9vPmOH/GHBQAGud3rJeKsT/PZ+f4jnShdUqvxdjPH+aRAkBCiNEP
7oPcbmiN3c+9PgFIaa2hJAlBAKYMRqzrQSPMuf/de6gNPYE4UFVGbtixYdXhYk3o
X8t0N4x4g0Qk6VYj
-----END PRIVATE KEY-----`;
export function decrypt(content: string) {
  const decrytp = new JSEncrypt();
  decrytp.setPrivateKey(pk);
  return decrytp.decryptLong(content);
}

export function getSearchParams() {
  const originSearch = location.search.slice(1);
  const decryptSearch = decrypt(originSearch) as string;
  return qs.parse(decryptSearch);
}
