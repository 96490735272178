
import { defineComponent } from 'vue';

const Transfer = defineComponent({
  type: 'custom',
  props: {
    isH5: {
      type: Boolean,
      default: () => false,
    },
    conversation:{
      type:Object,
      default: () => ({})
    }
  },
  setup(props:any, ctx:any) {
    const toggleShow = () => {
      const {userID,nick} = props.conversation.userProfile
      window.parent.postMessage(
          { type: "transfer", data: { userID, nick } },
          "*"
        );
    };

   

   
    return {
      toggleShow,
    };
  },
});
export default Transfer;
