import Axios from "axios";
// import { ElNotification, ElMessage } from "element-plus";
import JSONbig from "json-bigint";
import { encode } from "js-base64";
import { useStore } from "@/store";

let retryTokenCount = 0; // 重新获取第n次
const maxRetryCount = 3; // 重新获取token次数
const service = Axios.create({
  headers: {
    Accept: "*/*"
  },
  timeout: 15000,
  transformResponse: [
    function (data) {
      try {
        return JSONbig({ storeAsString: true }).parse(data);
      } catch (e) {
        return data;
      }
    }
  ]
});
service.defaults.retry = 4;
service.defaults.retryDelay = 800;

service.interceptors.request.use((config) => {
  const store = useStore();
  const action = config?.action || "";
  action && store.setLoading([action, true]);
  if (!config.data) config.data = {};
  const { data, origin } = config;
  // 非源数据进行base64加密
  if (!origin) {
    const jsonString = encode(JSON.stringify(data));
    config.data = {
      jsonString
    };
  }
  config.data.action = config.action;
  const token = store.token;
  if (token && config.url !== "/token") {
    config.headers.token = token;
  }
  return config;
});

service.interceptors.response.use(
  async (response) => {
    const store = useStore();
    const action = response.config?.action || "";
    action && store.setLoading([action, false]);
    const { code, isSuccess, description } = response?.data || {};
    if (code === 10000 || description === "安全令牌无效" || description === "The security token is invalid") {
      if (retryTokenCount > maxRetryCount) {
        return Promise.reject(response);
      }
      retryTokenCount++;
      await store.getToken();
      if (response?.config?.data) {
        response.config.data = JSON.parse(response?.config?.data);
      }
      response.data = await service({ ...response?.config, origin: true }); // 防止重复加密
      retryTokenCount = 0;
      return response.data;
    }
    if (!isSuccess && !response?.config?.closeInterceptors) {
      // ElMessage.error(description || "系统繁忙");
      return Promise.reject(response);
    } else {
      return response.data;
    }
  },
  (error) => {
    const store = useStore();
    const action = error.config?.action || "";
    action && store.setLoading([action, false]);
    if (error?.config?.closeInterceptors) {
      return error.response;
    }
    // ElNotification({
    //   title: "请求未响应",
    //   message: error?.response?.data?.error || "系统繁忙",
    //   type: "warning"
    // });
    return Promise.reject(error);
  }
);

export default service;
