// Image对象转base64
export function imageToBase64(image) {
  const canvas = document.createElement("canvas");

  canvas.width = image.width;
  canvas.height = image.height;

  const context = canvas.getContext("2d");
  context.drawImage(image, 0, 0, image.width, image.height);

  return canvas.toDataURL("image/png");
}

// 回调方式 Image url 转base64
export function urlToBase64(url, callback = null) {
  const image = new Image();

  image.setAttribute("crossOrigin", "Anonymous");
  image.src = url;

  image.onload = function () {
    const dataURL = imageToBase64(image);
    if (callback) {
      callback(dataURL);
    }
  };
}

// Promise方式 Image url 转base64
export function urlToBase64Async(url) {
  return new Promise((resolve, reject) => {
    urlToBase64(url, (data) => {
      resolve(data);
    });
  });
}

/**
   * 从base64编码中解析图片信息
   * @param {String} base64
   * eg: data:image/gif;base64,R0lGODlhAQABAPcAAAuvCwu1Cwy6DAy/DA
   *
   * @returns {Object}
   * eg:
   * {
      type: 'image/gif',
      ext: 'gif',
      data: 'R0lGODlhAQABAPcAAAuvCwu1Cwy6DAy/DA'
   */
export function parseBase64(base64) {
  const re = /data:(?<type>.*?);base64,(?<data>.*)/;
  const res = re.exec(base64);

  if (res) {
    return {
      type: res.groups.type,
      ext: res.groups.type.split("/").slice(-1)[0],
      data: res.groups.data
    };
  }
}

/**
 * 拷贝图片到剪切板
 * @param {*} imageUrl
 */
export async function copyImageToClipboard(imageUrl) {
  const base64Url = await urlToBase64Async(imageUrl);

  const parsedBase64 = parseBase64(base64Url);

  const type = parsedBase64.type;

  // 将base64转为Blob类型
  const bytes = atob(parsedBase64.data);
  const ab = new ArrayBuffer(bytes.length);
  const ua = new Uint8Array(ab);

  for (let i = 0; i < bytes.length; i++) {
    ua[i] = bytes.charCodeAt(i);
  }

  const blob = new Blob([ab], { type });

  navigator.clipboard.write([new ClipboardItem({ [type]: blob })]);
}
