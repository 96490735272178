
  import { defineComponent, reactive, toRefs } from "vue";
  import { TUIEnv } from "../TUIKit/TUIPlugin";
  import Drag from "../TUIKit/TUIComponents/components/drag";
  import { handleErrorPrompts } from "../TUIKit/TUIComponents/container/utils";
  import { loginIM } from "@/plugins/im";

  export default defineComponent({
    name: "App",
    components: {
      Drag
    },
    setup() {
      loginIM();
      const data = reactive({
        env: TUIEnv(),
        currentModel: "conversation",
        showCall: false,
        showCallMini: false
      });
      const TUIServer = (window as any)?.TUIKitTUICore?.TUIServer;
      const handleCurrentConversation = (value: string) => {
        data.currentModel = value ? "message" : "conversation";
      };
      // beforeCalling：在拨打电话前与收到通话邀请前执行
      const beforeCalling = (type: string, error: any) => {
        if (error) {
          handleErrorPrompts(error, type);
          return;
        }
        data.showCall = true;
      };
      // afterCalling：结束通话后执行
      const afterCalling = () => {
        data.showCall = false;
        data.showCallMini = false;
      };
      // onMinimized：组件切换最小化状态时执行
      const onMinimized = (oldMinimizedStatus: boolean, newMinimizedStatus: boolean) => {
        data.showCall = !newMinimizedStatus;
        data.showCallMini = newMinimizedStatus;
      };
      // onMessageSentByMe：在整个通话过程内发送消息时执行
      const onMessageSentByMe = async (message: any) => {
        TUIServer?.TUIChat?.handleMessageSentByMeToView(message);
        return;
      };
      return {
        ...toRefs(data),
        handleCurrentConversation,
        beforeCalling,
        afterCalling,
        onMinimized,
        onMessageSentByMe
      };
    }
  });
