<template>
  <div
    ref="contentInput"
    class="div-input"
    :class="modelValue ? 'placeholder_hide' : ''"
    :contenteditable="input"
    :placeholder="placeholder"
    @focus="ischecked = true"
    @blur="blurFn"
    @input="inputFn"
    v-html="text"
    @keydown="keyDown"
  ></div>
</template>

<script>
  export default {
    name: "DivInput",
    props: {
      input: {
        type: Boolean,
        default: true
      },
      placeholder: {
        type: String,
        default: ""
      },
      modelValue: {
        type: String,
        default: ""
      }
    },
    data() {
      return {
        ischecked: false,
        text: ''
      };
    },
    watch: {
      modelValue(n) {
        // 解决光标跳动BUG
        if (!this.ischecked) {
          this.text = this.modelValue;
        }
        if(!n){
            this.text = n
            this.$refs.contentInput.innerHTML = n;
        }
      }
    },
    methods: {
      inputFn(e) {
        if (e.keyCode === 13 && e.ctrlKey) return;
        const val = e.target.innerHTML;
        this.$emit("update:modelValue", val);
      },
      blurFn(e) {
        this.ischecked = false;
        this.text = this.modelValue;
        e.view.blur();
      },
      keyDown(event) {
        if (event.ctrlKey && event.keyCode === 13) {
          //ctrl+enter
          this.textareaRange();
        } else if (event.keyCode === 13) {
          //enter
          event.preventDefault(); // 阻止浏览器默认换行操作
          return false;
        }
      },
      //换行并重新定位光标位置
      textareaRange() {
        var el = this.$refs.contentInput;
        var range = document.createRange();
        //返回用户当前的选区
        var sel = document.getSelection();
        //div当前内容
        var content = el.innerHTML;
        //添加换行符\n
        el.innerHTML = content + "<div><br></div>";
        this.$emit("update:modelValue", el.innerHTML);

        //设置光标为当前位置
        range.setStart(el.childNodes[el.childNodes.length-1], 0);
        //使得选区(光标)开始与结束位置重叠
        range.collapse(true);
        //移除现有其他的选区
        sel.removeAllRanges();
        //加入光标的选区
        sel.addRange(range);
      }
    }
  };
</script>

<style lang="scss">
  .div-input {
    display: inline-block;
    min-width: 4em;
    border-bottom: 1px solid #ccc;
    outline: none;
    padding: 0 5px;
    &:focus {
      border-color: #333;
    }
    &:before {
      content: attr(placeholder);
      white-space: nowrap;
      width: 100%;
      color: #aaa;
      z-index: 0;
      cursor: text;
    }
    &.placeholder_hide {
      &:before {
        display: none;
      }
    }
  }
</style>
