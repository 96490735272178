/**
 * 防抖
 * @param {function} func 回调函数
 * @param {number} waitTime 毫秒
 * @returns
 */
export function debounce(func: any, waitTime = 500) {
  let timer: any;
  return function () {
    if (timer) clearTimeout(timer);
    timer = setTimeout(func, waitTime);
  };
}
