import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_LiteItem = _resolveComponent("LiteItem")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_input, {
      class: "search-input",
      clearable: "",
      placeholder: "Please input",
      modelValue: _ctx.search,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
      "prefix-icon": _ctx.Search
    }, null, 8, ["modelValue", "prefix-icon"]),
    _createElementVNode("ul", {
      class: _normalizeClass(["TUI-conversation-list", [_ctx.isH5 ? 'list-h5' : '']]),
      ref: "list",
      onMousewheel: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.scrollChange && _ctx.scrollChange(...args))),
      onScroll: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.scrollChange && _ctx.scrollChange(...args)))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataList, (item, index) => {
        return (_openBlock(), _createBlock(_component_LiteItem, {
          isH5: _ctx.isH5,
          key: index,
          currentID: _ctx.currentID,
          conversation: item,
          toggleID: _ctx.toggleID,
          handleConversation: {
        avator: _ctx.data.handleItemAvator,
        name: _ctx.data.handleItemName,
        showAt: _ctx.data.handleShowAt,
        showMessage: _ctx.data.handleShowMessage,
        time: _ctx.data.handleItemTime,
        userID: _ctx.data.handleItemUserID,
      },
          displayOnlineStatus: _ctx.displayOnlineStatus,
          userStatusList: _ctx.userStatusList,
          onToggle: _ctx.handleToggleListItem,
          onOpen: _ctx.handleListItem,
          onHandle: _ctx.handleItem,
          types: _ctx.types
        }, null, 8, ["isH5", "currentID", "conversation", "toggleID", "handleConversation", "displayOnlineStatus", "userStatusList", "onToggle", "onOpen", "onHandle", "types"]))
      }), 128))
    ], 34),
    _createVNode(_component_el_dialog, {
      title: "Add Tags",
      modelValue: _ctx.addTagDialog.visible,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.addTagDialog.visible) = $event)),
      width: _ctx.isH5?'80%':'50%'
    }, {
      footer: _withCtx(() => [
        _createElementVNode("span", null, [
          _createVNode(_component_el_button, {
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.addTagDialog.visible = false))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('TUIChat.取消')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_button, {
            type: "primary",
            onClick: _ctx.addTagSure
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('TUIChat.确定')), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_el_input, {
          modelValue: _ctx.addTagDialog.tag,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.addTagDialog.tag) = $event)),
          maxlength: "50",
          "show-word-limit": "",
          type: "textarea",
          placeholder: "",
          clearable: "",
          rows: 2
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }, 8, ["modelValue", "width"])
  ], 64))
}