import { defineStore } from "pinia";
import { getToken, getUserById, getQuickList, translate } from "@/api/index";
import { getSearchParams } from "@/utils/rsa";
import { getUserChat } from "@/utils/env";

export const useStore = defineStore("common", {
  state: () => ({
    token: "",
    userId: getSearchParams()?.userID,
    userInfo: {},
    quickList: [],
    loading: {} // 请求loading
  }),
  getters: {
    isSupplier() {
      return [2, 5].includes(this.userInfo?.type);
    }
  },
  actions: {
    async translate(data) {
      const res = await translate(data);
      return res.data.length ? res.data[0] : "-";
    },
    async getToken() {
      const res = await getToken();
      this.token = res?.data;
      return this.token;
    },
    async getUserInfo() {
      this.userId = getSearchParams()?.userID;
      if (this.userInfo?.userId) {
        return this.userInfo;
      }
      const res = await getUserById({
        userId: this.userId
      });
      this.userInfo = res?.data;
      const topUrl = document.referrer;
      const userChat = getUserChat();
      const userPage = topUrl.replace("https://", "").replace("www.", "").startsWith(userChat);
      if (userChat && userPage && this.userInfo.type !== 1) {
        location.href = "about:blank";
        return null;
      }
      return this.userInfo;
    },
    async getQuickList() {
      this.userId = getSearchParams()?.userID;
      const res = await getQuickList({
        userId: this.userId,
        pageNo: 1,
        pageSize: 99999
      });
      this.quickList = res?.data?.list;
      return this.quickList;
    },
    setLoading([action, isShow]) {
      const old = JSON.parse(JSON.stringify(this.loading));
      old[`${action}`] = isShow;
      this.loading = old;
    }
  }
});
