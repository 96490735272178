import request from "./request.js";
import { getTokeKeyId } from "@/utils/env";
export async function getToken() {
  const { appId, appSecret } = getTokeKeyId();
  return request({
    url: "/token",
    method: "post",
    origin: true,
    data: {
      appId,
      appSecret
    }
  });
}

// 获取用户详情
export async function getUserById(data) {
  return request({
    url: "/api",
    action: "userService.getById",
    method: "post",
    data
  });
}

// 获取快捷回复消息列表
export async function getQuickList(data) {
  return request({
    url: "/api",
    action: "replyService.getAll",
    method: "post",
    data
  });
}

// 添加快捷回复消息
export async function addQuick(data) {
  return request({
    url: "/api",
    action: "replyService.add",
    method: "post",
    data
  });
}

// 添加快捷回复消息
export async function delQuick(data) {
  return request({
    url: "/api",
    action: "replyService.delete",
    method: "post",
    data
  });
}

// 有道翻译
export async function translate(data) {
  return request({
    url: "/api",
    action: "youdaoService.translate",
    method: "post",
    data
  });
}
