import { setDataset, getDataset } from "@/utils/dom";
import { ElMessage } from "element-plus";

const copy = {
  install(app) {
    app.directive("copy", {
      mounted: (targetDom, binding) => {
        let onSuccess = () => {
          ElMessage.success("Copied  to clipboard!!!");
        };
        let onError;
        if (binding.value && typeof binding.value === "object") {
          setDataset(targetDom, binding.value.value);
          binding.value.onSuccess && (onSuccess = binding.value.onSuccess);
          onError = binding.value.onError;
        } else {
          setDataset(targetDom, binding.value?.value);
        }
        targetDom.addEventListener("click", async (e) => {
          e.stopPropagation();
          try {
            // 使用最新clipboard API
            await navigator.clipboard.writeText(getDataset(targetDom).copyValue);
            onSuccess();
          } catch (err) {
            let $input = document.createElement("textarea");
            $input.style.opacity = "0";
            $input.value = getDataset(targetDom).copyValue;
            document.body.appendChild($input);
            $input.select();

            const isSuccess = document.execCommand("copy");
            isSuccess ? onSuccess() : onError();
            document.body.removeChild($input);
            $input = null;
          }
        });
      },

      // 更新存储的值，存储在 dom 的 dataset 中
      updated: (el, binding) => {
        if (binding.value) {
          const value = typeof binding.value === "object" ? binding.value.value : binding.value;
          setDataset(el, value);
        }
      }
    });
  }
};

export default copy;
