import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-31d863cb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "upload-btn icon icon-image" }
const _hoisted_2 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    (!_ctx.isMute)
      ? (_openBlock(), _createElementBlock("input", {
          key: 0,
          title: _ctx.$t('TUIChat.图片'),
          type: "file",
          "data-type": "image",
          accept: "image/*",
          onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.sendUploadMessage && _ctx.sendUploadMessage(...args)))
        }, null, 40, _hoisted_2))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ]))
}