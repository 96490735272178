<template>
  <el-dialog
    v-model="visible"
    title="Add Quick Reply"
  >
    <el-form
        :model="form"
        ref="formRef"
        :rules="rules"
        label-width="80px"
        :inline="false"
        label-position="top"
    >
        <el-form-item label="title" prop="title">
            <el-input v-model="form.title" />
        </el-form-item>
        <el-form-item label="content" prop="content">
            <el-input v-model="form.content" type="textarea" />
        </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="visible = false">Cancel</el-button>
        <el-button type="primary" @click="onSubmit" :loading="loading">
          Confirm
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref,defineExpose, reactive } from 'vue'
import { useStore } from '@/store' 
import { addQuick } from '@/api'
import { ElMessage } from 'element-plus'

const store = useStore();

const visible = ref(false)
const loading = ref(false)
const form = reactive({
    title:'',
    content:''
})
const rules = {
  title: { required: true },
  content: { required: true },
}
const formRef = ref(null)
const onSubmit = async ()=>{
    const res = await formRef.value.validate()
    loading.value = true
    addQuick({
        userId:store.userId,
        ...form
    }).then(()=>{
        ElMessage.success('Add Quick Reply Success')
        store.getQuickList()
    }).finally(()=>{
        loading.value = false
        visible.value = false
    })
  
}
defineExpose({
    visible
})
</script>
<style lang="scss" scoped src="./style/index.scss"></style>
