import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0b9b5d87"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "home-TUIKit-main" }
const _hoisted_2 = { class: "chat" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TUISearch = _resolveComponent("TUISearch")!
  const _component_TUIConversation = _resolveComponent("TUIConversation")!
  const _component_TUIChat = _resolveComponent("TUIChat")!
  const _component_TUICallKit = _resolveComponent("TUICallKit")!
  const _component_Drag = _resolveComponent("Drag")!
  const _component_TUICallKitMini = _resolveComponent("TUICallKitMini")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("div", {
      class: _normalizeClass(_ctx.env?.isH5 ? 'conversation-h5' : 'conversation')
    }, [
      _createVNode(_component_TUISearch, { class: "search" }),
      _createVNode(_component_TUIConversation, { onCurrent: _ctx.handleCurrentConversation }, null, 8, ["onCurrent"])
    ], 2), [
      [_vShow, !_ctx.env?.isH5 || _ctx.currentModel === 'conversation']
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      _createVNode(_component_TUIChat)
    ], 512), [
      [_vShow, !_ctx.env?.isH5 || _ctx.currentModel === 'message']
    ]),
    _createVNode(_component_Drag, {
      show: _ctx.showCall,
      class: "callkit-drag-container",
      domClassName: "callkit-drag-container"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_TUICallKit, {
          allowedMinimized: true,
          allowedFullScreen: false,
          beforeCalling: _ctx.beforeCalling,
          afterCalling: _ctx.afterCalling,
          onMinimized: _ctx.onMinimized,
          onMessageSentByMe: _ctx.onMessageSentByMe
        }, null, 8, ["beforeCalling", "afterCalling", "onMinimized", "onMessageSentByMe"])
      ]),
      _: 1
    }, 8, ["show"]),
    _createVNode(_component_Drag, {
      show: _ctx.showCallMini,
      class: "callkit-drag-container-mini",
      domClassName: "callkit-drag-container-mini"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_TUICallKitMini, { style: {"position":"static"} })
      ]),
      _: 1
    }, 8, ["show"])
  ]))
}