const constant: any = {
  typeC2C: 'isC2C',
  typeGroup: 'isGroup',
  cancel: 'cancel',
  group: 'GROUP',
  handleMessage: {
    revoke: 'revoke',
    copy: 'copy',
    delete: 'delete',
    forward: 'forward',
    reply: 'reply',
    reference: 'reference'
  },
  at: '@',
  all: '所有人',
  typeText: 1,
  typeCustom: 2,
  typeImage: 3,
  typeAudio: 4,
  typeVideo: 5,
  typeFile: 6,
  typeFace: 8,
  typeForward: 'forward',
  typeMute: 'mute',
  typeOrder: 'order',
  typeTransaction: 'transaction', // 订单卡片
  typeFastTransaction: 'fast-transaction', // 快速交易订单卡片
  typePoints: 'points', // 积分订单卡片
  typeTransfer: 'transfer', // 转账卡片
  typeNotification: 'notification', // 系统通知
  notificationType:{
    negotiation:'negotiation', // 开始协商
    dispute:'dispute', // 仲裁
    evidence:'evidence', // 证据
    acceptNegotiation:'acceptNegotiation', // 接受协商
    cancelOrder:'cancelOrder', // 取消订单
    confirmOrder: 'confirmOrder', // 确认订单
    updateOrder: 'updateOrder', // 修改订单
    buyerWin: 'buyerWin', // 仲裁订单买家有理
    sellerWin: 'sellerWin', // 仲裁订单卖家有理
  },
  notificationFuncType:{
    orderDetail:'orderDetail', // 弹出卡片详情订单
    pointDetail:'pointDetail', // 弹出积分详情订单
  },
  notificationRoleType:{
    buyer:'买家', // 买家
    seller: '卖家', // 卖家
  },
  typeGroupCreate: "group_create", // 创建群组
  typeGroupCreateBussiness: "groupCreate", // 创建群组
  typeService: 'consultion',
  typeReply: 'quick_reply',
  typeEvaluate: 'evaluation',
  typeTextLink: 'text_link',
  typeAndroid: 'android',
  typeIphone: 'iphone',
  typeMini: 'miniprogram',
  typeUserTyping: 'user_typing_status',
  typeInputStatusIng: 'EIMAMSG_InputStatus_Ing',
  typeInputStatusEnd: 'EIMAMSG_InputStatus_End',
  scrollType: {
    toBottom: 'bottom',
    toTarget: 'target',
  },
  inViewType: {
    page: 'page',
    scroll: 'scroll',
  },
  TYPE_CALL_MESSAGE: 1,
  CALL_ACTION_TYPE: {
    INVITE: 1,
    CANCEL_INVITE: 2,
    ACCEPT_INVITE: 3,
    REJECT_INVITE: 4,
    INVITE_TIMEOUT: 5,
  },
};

export default constant;
