const highlight = {
  install(app) {
    app.directive("highlight", {
      mounted: (el, binding) => {
        const { value } = binding;
        if (value && typeof value === "object") {
          const { hText, text, clickFun } = value;
          el.innerHTML = text.replace(new RegExp(hText, "ig"), (a) => {
            return `<b  class="hightlight-text">${a}</b>`;
          });
          if (clickFun) {
            const eles = el.getElementsByClassName("hightlight-text");
            for (let i = 0; i < eles.length; i++) {
              // 点击事件是数组的话，每个高亮字体点击事件都不一样
              eles[i].onclick = clickFun instanceof Array ? clickFun[i] : clickFun;
            }
          }
        }
      },

      // 更新存储的值，存储在 dom 的 dataset 中
      updated: (el, binding) => {
        const { value } = binding;
        if (value && typeof value === "object") {
          const { hText, text, clickFun } = value;
          el.innerHTML = text.replace(new RegExp(hText, "ig"), (a) => {
            return `<b onClick="onClickView" class="hightlight-text">${a}</b>`;
          });
          if (clickFun) {
            const eles = el.getElementsByClassName("hightlight-text");
            for (let i = 0; i < eles.length; i++) {
              eles[i].onclick = clickFun instanceof Array ? clickFun[i] : clickFun;
            }
          }
        }
      }
    });
  }
};

export default highlight;
