import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-780a1ddf"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "call",
  id: "call"
}
const _hoisted_2 = ["title"]
const _hoisted_3 = ["title"]
const _hoisted_4 = {
  class: "call-main-content",
  ref: "dialog"
}
const _hoisted_5 = {
  key: 0,
  class: "icon icon-call-voice"
}
const _hoisted_6 = {
  key: 0,
  class: "icon icon-call-video"
}
const _hoisted_7 = { class: "uncall-dialog-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Transfer = _resolveComponent("Transfer")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_ctx.handleShowCallIcon(_ctx.conversation, _ctx.isH5))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: "call-icon",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleShowSelectDialog && _ctx.toggleShowSelectDialog(...args)))
        }, [
          _createElementVNode("i", {
            class: "icon icon-call",
            title: _ctx.$t('TUIChat.通话')
          }, null, 8, _hoisted_2),
          _createElementVNode("i", {
            class: "icon icon-down-arrow",
            title: _ctx.$t('TUIChat.通话')
          }, null, 8, _hoisted_3)
        ]),
        _withDirectives(_createElementVNode("div", {
          class: _normalizeClass(["call-main", [_ctx.isH5 && 'call-main-h5']])
        }, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", {
              class: "call-main-voice",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onClickCall(1)))
            }, [
              (_ctx.isH5)
                ? (_openBlock(), _createElementBlock("i", _hoisted_5))
                : _createCommentVNode("", true),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('TUIChat.语音通话')), 1)
            ]),
            _createElementVNode("div", {
              class: "call-main-video",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onClickCall(2)))
            }, [
              (_ctx.isH5)
                ? (_openBlock(), _createElementBlock("i", _hoisted_6))
                : _createCommentVNode("", true),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('TUIChat.视频通话')), 1)
            ]),
            _createElementVNode("footer", null, [
              (_ctx.isH5)
                ? (_openBlock(), _createElementBlock("span", {
                    key: 0,
                    class: "close",
                    onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.showSelectDialog = false), ["stop"]))
                  }, _toDisplayString(_ctx.$t('TUIChat.取消')), 1))
                : _createCommentVNode("", true)
            ])
          ], 512)
        ], 2), [
          [_vShow, _ctx.showSelectDialog]
        ]),
        _createVNode(_component_Dialog, {
          show: _ctx.showGroupUserDialog,
          isH5: _ctx.isH5,
          isHeaderShow: false,
          isFooterShow: false,
          background: false,
          "onUpdate:show": _cache[4] || (_cache[4] = (e) => (_ctx.showGroupUserDialog = e))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Transfer, {
              isSearch: true,
              title: _ctx.showTitle,
              list: _ctx.searchUserList,
              isH5: _ctx.isH5,
              isRadio: _ctx.conversation?.type === 'isC2C',
              onSearch: _ctx.handleSearch,
              onSubmit: _ctx.submit,
              onCancel: _ctx.cancle
            }, null, 8, ["title", "list", "isH5", "isRadio", "onSearch", "onSubmit", "onCancel"])
          ]),
          _: 1
        }, 8, ["show", "isH5"]),
        _createVNode(_component_Dialog, {
          show: _ctx.showUnsupportDialog,
          isH5: _ctx.isH5,
          isHeaderShow: true,
          isFooterShow: true,
          background: true,
          title: _ctx.$t('TUIChat.欢迎使用TUICallKit'),
          "onUpdate:show": _cache[6] || (_cache[6] = (e) => (_ctx.showUnsupportDialog = e))
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("p", null, _toDisplayString(_ctx.errorContent), 1),
                _withDirectives(_createElementVNode("p", null, [
                  _createTextVNode(_toDisplayString(_ctx.$t('TUIChat.请点击')) + " ", 1),
                  _createElementVNode("a", {
                    onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.openLink(_ctx.errorLink?.url)))
                  }, _toDisplayString(_ctx.$t(`TUIChat.${_ctx.errorLink?.label}`)), 1),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('TUIChat.进行体验')), 1)
                ], 512), [
                  [_vShow, Object.keys(_ctx.errorLink).length > 0]
                ])
              ])
            ])
          ]),
          _: 1
        }, 8, ["show", "isH5", "title"])
      ]))
    : _createCommentVNode("", true)
}