// 元素dataset属性兼容性很差,IE11以上才支持,所以写了个方法兼容下
export function getDataset(ele: any) {
  if (ele.dataset) {
    return ele.dataset;
  } else {
    const attrs = ele.attributes; // 元素的属性集合
    const dataset: any = {};
    let name;
    let matchStr;
    for (let i = 0; i < attrs.length; i++) {
      // 是否是data- 开头
      matchStr = attrs[i].name.match(/^data-(.+)/);
      if (matchStr) {
        // data-auto-play 转成驼峰写法 autoPlay
        name = matchStr[1].replace(/-([\da-z])/gi, function (all: any, letter: string) {
          return letter.toUpperCase();
        });
        dataset[name] = attrs[i].value;
      }
    }
    return dataset;
  }
}
// 设置 属性
export function setDataset(ele: { setAttribute: (arg0: string, arg1: any) => void }, value: any) {
  ele.setAttribute("data-copy-value", value);
}
