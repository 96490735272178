
  import { dayjs } from 'element-plus';
  import { defineComponent, watchEffect, reactive, toRefs } from "vue";

  export default defineComponent({
    props: {
      data: {
        type: Object,
        default: () => ({})
      }
    },
    setup(props: any, ctx: any) {
      const data = reactive({
        message: {
          id: "",
          functionType: ""
        }
      });

      watchEffect(() => {
        data.message = props.data;
      });

      const onClickView = () => {
        window.parent.postMessage(
          { type: "clickView", data: { id: data.message?.id, functionType: data.message?.functionType } },
          "*"
        );
      };

      return {
        ...toRefs(data),
        onClickView,
        dayjs
      };
    }
  });
