import { getSearchParams } from "@/utils/rsa";
// 拼接图片前缀
export function getAssets(url: string) {
  if (url.startsWith("http")) {
    return url;
  }
  const PREFIX = {
    development: "http://47.98.142.210:8888", // dev环境
    production: "http://im.rexcard.net/file" // 生产环境
  };
  const env: "development" | "production" = getSearchParams().assetsEnv || process.env.NODE_ENV || "production";
  return PREFIX[env] + "/" + url;
}

// http://47.98.142.210:8888/00/00/rBqzxmOs8XyAJhcmAAz-gGa4CmM648_300x100_72.jpg
export function getImage(url: string, width: string, height: string, ratio = "72") {
  if (url.startsWith("http")) {
    return url;
  }
  const path = url.split(".");
  return getAssets(`${path[0]}_${width}x${height}_${ratio}.${path[1]}`);
}

// 聊天id key
export function getChatKeyId() {
  const CHANT_KEY = {
    development: 1400789267, // dev环境
    production: 1400799240 // 生产环境
  };
  const env: "development" | "production" = getSearchParams().assetsEnv || process.env.NODE_ENV || "production";
  return CHANT_KEY[env];
}

// token id key
export function getTokeKeyId() {
  const TOKEN_KEY = {
    development: {
      appId: "1678950684946",
      appSecret: "9fe4cca72e384fc99a30f3f2aefee15c"
    }, // dev环境
    production: {
      appId: "1678949873715",
      appSecret: "6ab4bc2a31e34609a07b95c73801132e"
    } // 生产环境
  };
  const env: "development" | "production" = getSearchParams().assetsEnv || process.env.NODE_ENV || "production";
  return TOKEN_KEY[env];
}

// 聊天id key
export function getChatTopUrl() {
  const TOP_URL = {
    development: "", // dev环境
    production: "rexcard." // 生产环境
  };
  const env: "development" | "production" = getSearchParams().assetsEnv || process.env.NODE_ENV || "production";
  return TOP_URL[env];
}

// 官网聊天
export function getUserChat() {
  const TOP_URL = {
    development: "", // dev环境
    production: "rexcard.net" // 生产环境
  };
  const env: "development" | "production" = getSearchParams().assetsEnv || process.env.NODE_ENV || "production";
  return TOP_URL[env];
}
